import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
app.use(ElementPlus)

import router from './router'
app.use(router)


import VueClipboard from 'vue-clipboard2';
app.use(VueClipboard);

import CarModelLogo from './components/CarModelLogo.vue';
import TrackLogo from './components/Tracklogo.vue';
app.component('CarModelLogo', CarModelLogo);
app.component('TrackLogo', TrackLogo);

// if you're using CDN, please remove this line.
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowCircleRight,
  faClock,
  faCloud,
  faCloudSunRain,
  faCopy,
  faEllipsisV,
  faExclamationCircle,
  faExclamationTriangle,
  faHome,
  faInfoCircle,
  faMinus,
  faPlus,
  faPlusSquare,
  faTemperatureLow,
  faTractor,
  faUmbrella,
  faFlushed,
  faFlagCheckered,
  faDatabase,
  faHeartbeat,
  faCalendar,
  faTrophy,
  faRedo,
  faClipboardList,
  faEdit,
  faEllipsisH,
  faCalendarPlus
} from '@fortawesome/free-solid-svg-icons';
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { dom } from '@fortawesome/fontawesome-svg-core'
// Needed to use the bulma icon markup i.e. class="fas fa-angle-down"
dom.watch()

library.add(faAngleDown, faAngleLeft, faAngleRight, faArrowCircleRight, faClock, faCloud, faCloudSunRain, faCopy, faEllipsisV, faExclamationCircle, faExclamationTriangle, faFlagCheckered, faHome, faInfoCircle, faMinus, faPlus, faPlusSquare, faTemperatureLow, faTractor, faUmbrella, faFlushed, faDatabase, faHeartbeat, faCalendar, faTrophy, faRedo, faClipboardList, faEdit, faEllipsisH, faCalendarPlus)

app.config.globalProperties.$formatters =
{
  DateFormat(value)
  {
    if (value) {
      let dt = new Date(value);
      return Intl.DateTimeFormat().format(dt);
    }
  },

  TimeFormat(value) {
    if (value) {
      let dt = new Date(value);
      return dt.toLocaleTimeString();
    }
  },

  SplitTimeFormat(value) {
    if (value) {
      return value.join(' | ');
    }
  },

  GapDisplayFormat(value) {
    if (value) {
      return (value=='0:00.000') ? '' : value;
    }
  },

  SessionTypeLabel(value) {
    if (value) {
      let ret = value;
      switch (value) {
        case 'R':
          ret = "Race"
          break;
        case 'Q':
            ret = "Qualifying"
            break;
        case 'FP':
          ret = "Free Practice"
          break;
        default:
          break;
      }
      return ret;
    }
  }
}

app.mount('#app')