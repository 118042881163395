<template>
  <div
    class="hero is-fullheight-with-navbar"
    :class="'home-background-'+background.current"
  > 
    <div class="hero-head">
      <div class="container mt-6">
        <p class="title">
          A Community Called Belgium
        </p>
        <p class="subtitle">
          Looking to connect the universe with passion for srs recreational
          racing.
        </p>
      </div>
    </div>
    <div class="hero-body" />
  </div>
</template>

<script>
export default {
  name: "AccbViewHome",
  components: {},
  data() {
    return {
      images: [
        1,2,3,4
      ],
      background:
      {
        animation: "fade",
        current: 1,
        max: 4,
        interval: 5000
      }
    };
  },
  mounted()
  {
    this.InitializeSlideShow()
  },
  methods:
  {
    InitializeSlideShow()
    {
      setTimeout(this.ImageNext, this.background.interval)
    },
    ImageNext()
    {
      if (this.background.current==this.background.max)
      {
        this.background.current = 1;
      }
      else
      {
        this.background.current++
      }
      setTimeout(this.ImageNext, this.background.interval)
    }
  }
};
</script>
<style lang="scss">
$home-background-images: (
  1: url(../assets/slideshow/slide_image_1.jpg),
  2: url(../assets/slideshow/slide_image_2.jpg),
  3: url(../assets/slideshow/slide_image_3.jpg),
  4: url(../assets/slideshow/slide_image_4.jpg),
);

@each $idx, $path in $home-background-images {
  .home-background-#{$idx} {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)),
      #{$path};
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position-x: center;
    transition: background-image 2s;
  }
}
</style>
