<template>
  <div id="app">
    <PageHeader 
      :login-user="loginUser" 
      :admin-around="adminAround"
    />
    <router-view 
      :login-state-change-handler="HandleLoginStateChange" 
      :login-user="loginUser" 
      :admin-around="adminAround"
    />
  </div>
</template>

<script>

require('./main.scss');

import PageHeader from "./components/PageHeader.vue";
import axios from 'axios';
axios.defaults.withCredentials = true

export default {
  components :
  {
    PageHeader
  },
  data () {
    return {
      loginUser: null
    }
  },
  computed: {
    // Is an admin logged int
    adminAround: function () {
        // `this` points to the vm instance
        return this.loginUser !== null && this.loginUser.Roles.includes("admin");
    }
  },
  watch: {
      '$route' (to) {
        document.title = to.meta.title || 'ACC Belgium'
        if(to.meta.requiresAuth)
        {
          // Reload the user session
          this.UserSessionRestore();
        }
      }
    },
    mounted () 
  {
    document.title = process.env.VUE_APP_TITLE
      // Reload the user session
      this.UserSessionRestore();
  },
  methods: {
    HandleLoginStateChange : function(data)
    {
      this.loginUser = data;
    },
    Logout: function()
        {
            axios.post (process.env.VUE_APP_API_URL + '/usersession/logout/')
                .then(
                    this.HandleLoginStateChange(null)
                )
        },
    UserSessionRestore: function()
      {
          axios.post (process.env.VUE_APP_API_URL + '/usersession/restore/')
              .then(response => {
                  this.HandleLoginStateChange(response.data === '' ? null : response.data)
                }
              )
      }
  }
};

</script>
