<template>
  <figure :class="'image is-' + size">
    <img :src="getImgUrl(trackname)">
  </figure>
</template>
<script>
export default {
    name: 'TrackLogo',
    props:
    { 
        trackname: {
            default: "nurburgring",
            type: String
        },
        size: {
            default: "128x128",
            type: String
        }
    },
    methods: {
      getImgUrl(trackname) {
          var images = require.context('../assets/tracks/', false, /.*\.png$/);
          return images('./' + this.getImageNameByTrack(trackname) + ".png")
      },
      getImageNameByTrack(value) {

          let ret = value;
          switch (value) {
              case 'nurburgring':
            case 'nurburgring_2019':
            case 'nurburgring_2020':
                ret = 'nurburgring-md';
                break;
            case 'barcelona':
            case 'barcelona_2019':
            case 'barcelona_2020':
                ret = 'barcelona-md';
                break;
            case 'paul_ricard':
            case 'paul_ricard_2019':
            case 'paul_ricard_2020':
                ret = 'paul-ricard-md';
                break;
                case 'silverstone':
            case 'silverstone_2019':
            case 'silverstone_2020':
                ret = 'silverstone-md';
                break;
            case 'mount_panorama':
            case 'mount_panorama_2019':
            case 'mount_panorama_2020':
                ret = 'mount_panorama-md';
                break;
            case 'spa':
            case 'spa_2019':
            case 'spa_2020':
                ret = 'spa-md';
                break;
            case 'suzuka':
            case 'suzuka_2019':
            case 'suzuka_2020':
                ret = 'suzuka-md';
                break;
            case 'zandvoort':
            case 'zandvoort_2019':
            case 'zandvoort_2020':
                ret = 'zandvoort-md';
                break;
            case 'zolder':
            case 'zolder_2019':
            case 'zolder_2020':
                ret = 'zolder-md';
                break;                
            case 'misano':
            case 'misano_2019':
            case 'misano_2020':
                ret = 'misano-md';
                break;
            case 'monza':
            case 'monza_2019':
            case 'monza_2020':
                ret = 'monza-md';
                break;
            case 'imola':
                ret = 'imola-md';
                break;
            case 'laguna_seca':                
            case 'laguna_seca_2019':
                ret = 'laguna_seca-md';
                break;
            case 'kyalami':
            case 'kyalami_2019':
                ret = 'kyalami-md';
                break;
            case 'brands_hatch':
            case 'brands_hatch_2019':
            case 'brands_hatch_2020':
                ret = 'brands-hatch-md';
                break;
            case 'hungaroring':
            case 'hungaroring_2019':
            case 'hungaroring_2020':
                ret = 'hungaroring-md';
                break;
            case 'oulton_park':
            case 'oulton_park_2019':
            case 'oulton_park_2020':
                ret = 'oulton_park-md';
                break;
            case 'donington':
            case 'donington_2019':
            case 'donington_2020':
                ret = 'donington-md';
                break;
            case 'snetterton':
            case 'snetterton_2019':
            case 'snetterton_2020':
                ret = 'snetterton-md';
                break;
            default:
                ret = 'notfound';
                break;
          }
          return ret;
      }
  }
}
</script>