<template>
  <nav
    class="navbar is-fixed-top is-spaced has-text-weight-semibold is-uppercase touch-max-width"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a
        class="navbar-item"
        href="/"
      >
        <img src="../assets/logo.png">
      </a>
      <a
        role="button"
        class="navbar-burger nav-toggle"
        aria-label="menu"
        aria-expanded="false" 
        :class="{ 'is-active': showNav }"
        @click="showNav = !showNav"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>
    <div
      id="HeaderNavigation"
      class="navbar-menu"
      :class="{ 'is-active': showNav }"
      @click="showNav = !showNav"
    >
      <div class="navbar-start">
        <router-link
          to="/"
          class="navbar-item"
        >
          Home
        </router-link>
        <router-link
          to="/weeklyleague"
          class="navbar-item"
        >
          Weekly League
        </router-link>
        <router-link
          to="/sundayleague"
          class="navbar-item"
        >
          Sunday League
        </router-link>
        <router-link
          to="/hotlapping"
          class="navbar-item"
        >
          Hotlapping
        </router-link>
        <!--router-link to="/racingschedule" class="navbar-item">Calendar</router-link-->
      </div>

      <div class="navbar-end">
        <router-link
          v-if="adminAround"
          class="navbar-item p-4 is-size-7"
          to="/admin"
        >
          <small>Administration</small>
        </router-link>
        <router-link
          class="navbar-item p-4 is-size-7"
          to="/rulesofcleanracing"
        >
          <small>Rules of Clean Racing</small>
        </router-link>
        <router-link
          class="navbar-item p-4 is-size-7"
          to="/privacy"
        >
          <small>Privacy</small>
        </router-link>
        <router-link
          v-if="loginUser===null"
          class="navbar-item p-4 is-size-7"
          to="/login"
        >
          <small>Login / Register</small>
        </router-link>
        <router-link
          v-else
          class="navbar-item p-4 is-size-7"
          to="/user/myprofile"
        >
          <small>Profile</small>
        </router-link>
        <a
          class="navbar-item p-0"
          href="https://discord.gg/RA8SQ5w"
        >
          <img
            src="../assets/Discord-Logo+Wordmark-White.svg"
            alt="Join us on Discord"
            width="85"
          >
        </a>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
    name: 'PageHeader',
    props: {
        loginUser: 
        {
          type: Object,
          default: null
        },
        adminAround: Boolean
    },
    data ()
    {
        return {
            showNav: false
        }
    }
}
</script>
