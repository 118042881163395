import { createRouter, createWebHistory } from 'vue-router'
import AccbViewHome from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      requiresAuth: false,
      title: `${process.env.VUE_APP_TITLE} - Home`
    },
    component: AccbViewHome
  },
  {
    path: '/session/:id',
    name: 'Session',
    meta: {
      requiresAuth: false,
      title: `${process.env.VUE_APP_TITLE} - Session Result`
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sessionview" */ '../views/Session.vue')
  },
  {
    path: '/raceresult/:id',
    name: 'RaceResult',
    meta: {
      requiresAuth: false,
      title: `${process.env.VUE_APP_TITLE} - Race Result`
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "raceresult" */ '../views/RaceResult.vue')
  },
  {
    path: '/series/:series/season/:season',
    name: 'Season',
    meta: {
      requiresAuth: false,
      title: `${process.env.VUE_APP_TITLE} - Season Dashboard`
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "seasonview" */ '../views/Season.vue')
  },
  {
    path: '/weeklyleague',
    name: 'WeeklyLeague',
    meta: {
      requiresAuth: false,
      title: `${process.env.VUE_APP_TITLE} - Weekly League`
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "weeklyleague" */ '../views/Leagues_WeeklyLeague.vue')
  },
  {
    path: '/sundayleague',
    name: 'SundayLeague',
    meta: {
      requiresAuth: false,
      title: `${process.env.VUE_APP_TITLE} - Sunday League`
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sundayleague" */ '../views/Leagues_SundayLeague.vue')
  },
  {
    path: '/racingschedule',
    name: 'RacingSchedule',
    meta: {
      requiresAuth: false,
      title: `${process.env.VUE_APP_TITLE} - Racing Schedule`
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "racingschedule" */ '../views/RacingSchedule.vue')
  },
  {
    path: '/hotlapping',
    name: 'Hotlapping',
    meta: {
      requiresAuth: false,
      title: `${process.env.VUE_APP_TITLE} - Hotlapping Current`
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hotlapping" */ '../views/Hotlapping.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      requiresAuth: false,
      title: `${process.env.VUE_APP_TITLE} - Login`
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/user/myprofile',
    name: 'my_profile',
    meta: {
      requiresAuth: true,
      nothingtosee: true,
      title: `${process.env.VUE_APP_TITLE} - My Profile`
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "my_profile" */ '../views/user/MyProfile.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    meta: {
      requiresAuth: false,
      title: 'ACC Belgium - Privacy'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "events" */ '../views/Privacy.vue')
  },
  {
    path: '/rulesofcleanracing',
    name: 'Rules of Clean Racing',
    meta: {
      requiresAuth: false,
      title: `${process.env.VUE_APP_TITLE} - Rules of Clean Racing`
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "events" */ '../views/RulesOfCleanRacing.vue')
  },
  //
  // Admin section
  //
  {
    path: '/admin',
    //name: 'Administration',
    meta:
    {
      requiresAuth: true,
      title: `${process.env.VUE_APP_TITLE} - Administration`,
      crumbText: 'Administration',
      symbol: 'fas fa-home'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Home.vue'),
    children: 
    [
      {
        path: '',
        name: 'Administration Dashboard',
        meta: {
          requiresAuth: true,
          title: `${process.env.VUE_APP_TITLE} - Administration Dashboard`,
          crumbText: 'Administration',
          symbol: 'fas fa-home'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "admin_dashboard" */ '../views/admin/Dashboard.vue')
      },

      // Racing menu
      {
        path: 'racing',
        name: 'Racing',
        meta: {
          requiresAuth: true,
          title: `${process.env.VUE_APP_TITLE} - Racing`,
          crumbText: 'Racing',
          symbol: 'fas fa-tractor'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "admin_racing" */ '../views/admin/racing/Dashboard.vue')
      },
        
      {
        path: 'racing/schedule',
        name: 'Racing Schedule',
        meta: {
          requiresAuth: true,
          nothingtosee: true,
          title: `${process.env.VUE_APP_TITLE} - Racing Schedule`,
          crumbText: 'Schedule',
          symbol: 'fas fa-calendar'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "admin_racing_schedule" */ '../views/admin/racing/Schedule.vue')
      },
      {
        path: 'racing/series',
        name: 'Racing Series',
        meta: {
          requiresAuth: true,
          nothingtosee: true,
          title: `${process.env.VUE_APP_TITLE} - Racing Series`,
          crumbText: 'Series',
          symbol: 'fas fa-redo'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "admin_racing_series" */ '../views/admin/racing/Series.vue')
      },
      {
        path: 'racing/sessions',
        name: 'Racing Sessions',
        meta: {
          requiresAuth: true,
          nothingtosee: true,
          title: `${process.env.VUE_APP_TITLE} - Racing Sessions`,
          crumbText: 'Sessions',
          symbol: 'fas fa-clipboard-list'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "admin_racing_sessions" */ '../views/admin/racing/Sessions.vue')
      },
      // Forms
      {
        path: 'racing/createweekend',
        name: 'Create Weekend',
        meta: {
          requiresAuth: true,
          nothingtosee: true,
          title: `${process.env.VUE_APP_TITLE} - Create Weekend`,
          crumbText: 'Create Weekend',
        },
        component: () => import(/* webpackChunkName: "admin_racing_updateweekend" */ '../views/admin/racing/Form_UpdateWeekend.vue')
      },

      {
        path: 'racing/updateweekend/:id',
        name: 'Update Weekend',
        meta: {
          requiresAuth: true,
          nothingtosee: true,
          title: `${process.env.VUE_APP_TITLE} - Update Weekend`,
          crumbText: 'Update Weekend',
        },
        component: () => import(/* webpackChunkName: "admin_racing_updateweekend" */ '../views/admin/racing/Form_UpdateWeekend.vue')
      },
      
      // Member section
      {
        path: 'racers',
        name: 'Racers',
        meta: {
          requiresAuth: true,
          nothingtosee: true,
          title: `${process.env.VUE_APP_TITLE} - Profiles`,
          crumbText: 'Profiles',
          symbol: 'fa fa-flushed'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "admin_members" */ '../views/admin/racers/Dashboard.vue')
      },
      // Member section
      {
        path: 'racers/profiles',
        name: 'Profiles',
        meta: {
          requiresAuth: true,
          nothingtosee: true,
          title: `${process.env.VUE_APP_TITLE} - Profiles`,
          crumbText: 'Profiles',
          symbol: 'fa fa-flushed'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "admin_members" */ '../views/admin/racers/Profiles.vue')
      },
      // Member section
      {
        path: 'racers/teams',
        name: 'Teams',
        meta: {
          requiresAuth: true,
          nothingtosee: true,
          title: `${process.env.VUE_APP_TITLE} - Profiles`,
          crumbText: 'Profiles',
          symbol: 'fa fa-flushed'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "admin_members" */ '../views/admin/racers/Teams.vue')
      },
      {
        path: 'data',
        name: 'Data',
        meta: {
          requiresAuth: true,
          nothingtosee: true,
          title: `${process.env.VUE_APP_TITLE} - Data`,
          crumbText: 'Data',
          symbol: 'fas fa-database'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "admin_data" */ '../views/404.vue')
      },
      {
        path: 'data/tracks',
        name: 'Tracks',
        meta: {
          requiresAuth: true,
          nothingtosee: true,
          title: `${process.env.VUE_APP_TITLE} - Tracks`,
          crumbText: 'Tracks',
          symbol: 'fa fa-flushed'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "admin_data_tracks" */ '../views/admin/data/Tracks.vue')
      },
      {
        path: 'data/cars',
        name: 'Cars',
        meta: {
          requiresAuth: true,
          nothingtosee: true,
          title: `${process.env.VUE_APP_TITLE} - Cars`,
          crumbText: 'Cars & Brands',
          symbol: 'fa fa-flushed'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "admin_data_cars" */ '../views/admin/data/Cars.vue')
      },
      {
        path: 'system',
        name: 'System',
        meta: {
          requiresAuth: true,
          nothingtosee: true,
          title: `${process.env.VUE_APP_TITLE} - System`,
          crumbText: 'System',
          symbol: 'fas fa-heartbeat'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "admin_system" */ '../views/404.vue')
      },
      {
        path: 'system/logs',
        name: 'Logs',
        meta: {
          requiresAuth: true,
          nothingtosee: true,
          title: `${process.env.VUE_APP_TITLE} - Cars`,
          crumbText: 'Cars & Brands',
          symbol: 'fa fa-flushed'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "admin_data_cars" */ '../views/admin/system/Logs.vue')
      },
      {
        path: 'system/jobs',
        name: 'Jobs',
        meta: {
          requiresAuth: true,
          nothingtosee: true,
          title: `${process.env.VUE_APP_TITLE} - Cars`,
          crumbText: 'Cars & Brands',
          symbol: 'fa fa-flushed'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "admin_data_cars" */ '../views/admin/system/Jobs.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactPathActiveClass: 'is-white',
  linkActiveClass: 'is-white',
  routes
})

export default router
