<template>
  <figure
    class="image is-24x24 is-inline-block"
    style="margin-left: 0 !important; margin-right: 10px; vertical-align: bottom;    margin-top: -1px;"
  >
    <img :src="getImgUrl(carModelId)">
  </figure>
</template>
<script>

export default {
    name: 'CarModelLogo',
    props: { 
        carModelId:
        {
            type: Number,
            default: 0
        }
    },
  data ()
  {
      return {
        
      }
  },
  methods: {
    getImgUrl(carManu) {
        var images = require.context('../assets/carmanufbadges/', false, /Logo_.*\.png$/);
        return images('./Logo_' + this.getManufaturarNameById(carManu) + ".png")
    },
    getManufaturarNameById(value) {

        let ret = value;
        switch (parseInt(value)) {
        case 0:
            ret = 'Porsche'; //'Porsche 991 GT3 R';
            break;
        case 1:
            ret = 'Mercedes'; //'Mercedes-AMG GT3'
            break;
        case 2:
            ret = 'Ferrari'; //'Ferrari 488 GT3'
            break;
        case 3:
            ret = 'Audi'; //'Audi R8 LMS'
            break;
        case 4:
            ret = 'Lamborghini'; //'Lamborghini Huracan GT3'
            break;
        case 5:
            ret = 'McLaren'; //'McLaren 650S GT3'
            break;
        case 6:
            ret = 'Nissan'; //'Nissan GT-R Nismo GT3 2018'
            break;
        case 7:
            ret = 'BMW'; //'BMW M6 GT3'
            break;
        case 8:
            ret = 'Bentley'; //'Bentley Continental GT3 2018'
            break;
        case 9:
            ret = 'Porsche'; //'Porsche 991II GT3 Cup'
            break;
        case 10:
            ret = 'Nissan'; //'Nissan GT-R Nismo GT3 2017'
            break;
        case 11:
            ret = 'Bentley'; //'Bentley Continental GT3 2016'
            break;
        case 12:
            ret = 'AstonMartin'; //'Aston Martin V12 Vantage GT3'
            break;
        case 13:
            ret = 'Lamborghini'; //'Lamborghini Gallardo R-EX'
            break;
        case 14:
            ret = 'Jaguar'; //'Jaguar G3'
            break;
        case 15:
            ret = 'Lexus'; //'Lexus RC F GT3'
            break;
        case 16:
            ret = 'Lamborghini'; //'Lamborghini Huracan Evo (2019)'
            break;
        case 17:
            ret = 'Honda'; //'Honda NSX GT3';
            break;
        case 18:
        case 33:
            ret = 'Lamborghini'; //'Lamborghini Huracan SuperTrofeo';
            break;
        case 19:
            ret = 'Audi'; //'Audi R8 LMS Evo (2019)';
            break;
        case 20:
            ret = 'AstonMartin'; //'AMR V8 Vantage (2019)';
            break;
        case 21:
            ret = 'Honda'; //'Honda NSX Evo (2019)';
            break;
        case 22:
        case 35:
            ret = 'McLaren'; //'McLaren 720S GT3 (2019)';
            break;
        case 23:
            ret = 'Porsche'; //'Porsche 911II GT3 R (2019)';
            break;
        case 24:
            ret = 'Ferrari'; //'Ferrari 488 GT3 Evo 2020';
            break;
        case 25:
            ret = 'Mercedes'; //'Mercedes-AMG GT3 2020';
            break;
        case 26:
        case 32:
            ret = 'Ferrari'; //'Ferrari 488 GT3 Challenge Evo';
            break;
        case 27:
            ret = 'BMW'; //'BMW M2 CS Racing';
            break;
        case 28:
        case 34:
            ret = 'Porsche'; //'Porsche 911 GT3 Cup (Type 992)';
            break;
        case 29:
            ret = 'Lamborghini'; //'Lamborghini Huracán Super Trofeo EVO2';
            break;
        case 30:
            ret = 'BMW'; //'BMW M4 GT3';
            break;
        case 31:
            ret = 'Audi'; //'Audi R8 LMS GT3 evo II';
            break;
        case 50:
            ret = 'Alpine'; //'Alpine A110 GT4';
            break;
        case 51:
            ret = 'AstonMartin'; //'AMR V8 Vantage GT4';
            break;
        case 52:
            ret = 'Audi'; //'Audi R8 LMS GT4';
            break;
        case 53:
            ret = 'BMW'; //'BMW M4 GT4';
            break;
        case 55:
            ret = 'Chevrolet'; //'Chevrolet Camaro GT4';
            break;
        case 56:
            ret = 'Ginetta'; //'Ginetta G55 GT4';
            break;
        case 57:
            ret = 'KTM'; //'KTM X-Bow GT4';
            break;
        case 58:
            ret = 'Maserati'; //'Maserati MC GT4';
            break;
        case 59:
            ret = 'McLaren'; //'McLaren 570S GT4';
            break;
        case 60:
            ret = 'Mercedes'; //'Mercedes-AMG GT4';
            break;
        case 61:
            ret = 'Porsche'; //'Porsche 718 Cayman GT4'
            break;
        default:
            break;
        }
        return ret;
    }
  }
}
</script>